// Entry point for the build script in your package.json
import '@hotwired/turbo-rails';
import Clipboard from '@stimulus-components/clipboard';
import PasswordVisibility from 'stimulus-password-visibility';

import { application } from '../controllers/application';
import PasswordStrengthController from '../controllers/password_strength_controller';

application.register('clipboard', Clipboard);
application.register('password-visibility', PasswordVisibility);
application.register('password-strength', PasswordStrengthController);
